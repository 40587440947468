<template>
  <div v-bind:class="['we-block-container']" v-bind:style="getStyle">
    <!-- Block Actions -->
    <div class="we-block-container-menu">
      <div class="btn-group" role="group" aria-label="Basic example">
        <!-- Move Up -->
        <button
          type="button"
          v-bind:disabled="!isMoveUpAvailable"
          v-bind:class="[
            'btn btn-sm btn-primary',
            !isMoveUpAvailable ? 'btn-disabled' : '',
          ]"
          v-tooltip.top="'Yukarı Taşı'"
          v-on:click="changeOrder()"
        >
          <i class="fas fa-chevron-up fa-sm"></i>
        </button>
        <!-- ./Move Up -->

        <!-- Move Down -->
        <button
          type="button"
          v-bind:disabled="!isMoveDownAvailable"
          v-bind:class="[
            'btn btn-sm btn-primary',
            !isMoveDownAvailable ? 'btn-disabled' : '',
          ]"
          v-tooltip.top="'Aşağı Taşı'"
          v-on:click="changeOrder('down')"
        >
          <i class="fas fa-chevron-down fa-sm"></i>
        </button>
        <!-- ./Move Down -->

        <!-- Duplicate -->
        <button
          type="button"
          class="btn btn-sm btn-primary"
          v-tooltip.top="'Çoğalt'"
          v-on:click="duplicateBlock"
        >
          <i class="fas fa-copy fa-sm"></i>
        </button>
        <!-- ./Duplicate -->

        <!-- Open Settings Menu -->
        <button
          type="button"
          class="btn btn-sm btn-primary"
          v-tooltip.top="'Ayarlar'"
          v-on:click="isSettingMenuOpen = true"
        >
          <i class="fas fa-cog fa-sm"></i>
        </button>
        <!-- ./Open Settings Menu -->

        <!-- Remove -->
        <button
          type="button"
          class="btn btn-sm btn-danger"
          v-on:click="onRemove"
          v-tooltip.top="'Kaldır'"
        >
          <i class="fas fa-trash fa-sm"></i>
        </button>
        <!-- ./Remove -->
      </div>
    </div>
    <!-- ./Block Actions -->

    <!-- Block Settings -->
    <WeSidebarMenu
      v-if="isSettingMenuOpen"
      title="Blok Düzenle"
      v-on:close="isSettingMenuOpen = false"
    >
      <BlockSettings v-model="computedData" />
    </WeSidebarMenu>
    <!-- ./Block Settings -->

    <!-- Block Title -->
    <div class="mb-3">
      <WeInput
        class="mb-0"
        label="Başlık"
        v-model="computedData.title"
        placeholder="Başlık Giriniz"
      />
    </div>
    <!-- ./Block Title -->

    <!-- Content -->
    <slot></slot>
    <!-- ./Content -->
  </div>
</template>

<script>
const BlockSettings = () => import("./components/BlockSettings/Index.vue");
export default {
  name: "RowBlock",
  components: {
    BlockSettings,
  },
  props: {
    blocks: {
      default: () => [],
    },
    blockIndex: {
      default: null,
    },
    block: {
      default: null,
    },
  },
  model: {
    prop: "block",
  },
  data() {
    return {
      isSettingMenuOpen: false,
    };
  },
  methods: {
    onRemove() {
      this.$swal({
        title: "İşlemi Onalıyor Musunuz ?",
        text: "Silmek istediğiniz blok içeriği tamamen kaldırılacak.",
        icon: "question",
        confirmButtonText: "Sil",
        showCancelButton: true,
        cancelButtonText: "İptal",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.$emit("remove", this.blockIndex);
        }
      });
    },
    changeOrder(direction = "up") {
      const isMoveAvailable =
        (direction == "up" && this.isMoveUpAvailable) ||
        (direction == "down" && this.isMoveDownAvailable);

      if (isMoveAvailable) {
        const currentIndex = this.blockIndex;
        let targetIndex = currentIndex + (direction == "up" ? -1 : 1);
        if (targetIndex <= 0) targetIndex = 0;

        if (this.blocks[targetIndex]) {
          const temp = this.blocks[targetIndex];
          this.blocks[targetIndex] = this.blocks[currentIndex];
          this.blocks[currentIndex] = temp;
          this.blocks.sort();
        }
      }
    },
    duplicateBlock() {
      const currentBlock = this.blocks[this.blockIndex];
      const newBlock = helper.clone(currentBlock);

      if (newBlock.hasOwnProperty("id")) delete newBlock.id;
      this.blocks.push(newBlock);
    },
  },
  computed: {
    componentId() {
      return "we-row-block-" + helper.getRandomInteger(1, 999999);
    },
    isMoveUpAvailable() {
      return (
        (this.blocks && this.blocks.length > 1 && this.blockIndex !== 0) ||
        false
      );
    },
    isMoveDownAvailable() {
      return this.blockIndex !== this.blocks.length - 1;
    },
    getStyle() {
      const {
        backgroundColor,
        backgroundImage,
        backgroundSize,
        backgroundRepeat,
        borderWidth,
        borderStyle,
        borderColor,
        padding,
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
        rowGap,
        columnGap,
      } = this.block;

      return {
        backgroundColor,
        "background-image": backgroundImage
          ? `url('${backgroundImage}')`
          : "none",
        "background-size": `${backgroundSize}%`,
        backgroundRepeat,
        "border-width": `${borderWidth}px`,
        "border-style": borderStyle,
        "border-color": borderColor,
        padding: `${padding}px`,
        "border-top-left-radius": `${borderTopLeftRadius}px`,
        "border-top-right-radius": `${borderTopRightRadius}px`,
        "border-bottom-left-radius": `${borderBottomLeftRadius}px`,
        "border-bottom-right-radius": `${borderBottomRightRadius}px`,
        gap: `${rowGap}px ${columnGap}px`,
      };
    },
    computedData: {
      get() {
        return this.block;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.we-block-container {
  transition: 300ms all;
  position: relative;
  &:not(.we-block-card):hover {
    border-color: rgb(77, 77, 254) !important;
    .we-block-container-menu {
      display: block;
    }
  }
  &-menu {
    z-index: 1;
    top: -15px;
    left: 10px;
    position: absolute;
    padding: 0;
    margin: 0;
    list-style: none;
    display: none;
  }
  .btn:disabled {
    opacity: 0.9 !important;
  }
}
</style>